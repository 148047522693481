export const header = {
    small: false,
    title: "Complaints procedure",
    h1: true,
    bgColor: "#0bbcbf",
    displayBtn: false,
    padding: true,
}


export const complaints = [

    {
        title: "What constitutes a complaint?        ",
        description: "Any expression of dissatisfaction, whether oral or written, and whether justified or not, from or  on behalf of an eligible complainant about our services.        "
    },
    {
        title: "Who is eligible to complain?        ",
        description: "All complaints regardless of whether the complainant is eligible to complain will be dealt with according to this procedure.        "
    },
    {
        title: "How to complain Complaints can be made by letter or email to:           ",
        description: "The Managing Director, Free Motor Legal Ltd, 7 Ploughmans Lane, Haxby, York, YO32 2WR Email: complaints@freemotorlegal.co.uk        "
    },
    {
        title: "What happens if we receive a complaint?        ",
        description: "We will aim to resolve any complaint at the earliest possible stage. However, if we are unable   to resolve a complaint by close of business on the day following receipt of a complaint we will:        ",
        itemList: [
            {
                title: "Within five business days of receiving a complaint, send the complainant a written acknowledgement together with a copy of our complaints handling procedure;                ",
                description: ""
            }, 
            {
                title: "Investigate the complaint. This will be carried out by the Managing Director who has authority to offer redress and will ensure complaints are handled fairly, consistently and promptly;                ",
                description: ""
            },             
            {
                title: "Within 2 weeks of receiving the complaint, send the complainant a written final response (whether or not complaint is accepted) or reject the complaint giving our reasons for doing so.                ",
                description: ""
            }, 
        ]
    },
    {
        title: "What next?",
        description: "If the complainant remains dissatisfied with our final response the matter may be referred within 3 months to:",
        itemList: [
            {
                title: "The Legal Ombudsman. We shall include the address, phone number and email address of the Regulator in our final response.  We will consider the complaint closed if we do not receive a reply to our final response within 30 days.",
                description: ""
            }
        ]
    },
]