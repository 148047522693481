import React from "react";
import { header, complaints } from "../utils/how-it-works/complaintsInformations";
import Banner from "../components/banner/banner";
import Layout from "../layouts"
import ListItem from "../components/list-item/list-item";
import "../styles/pages/privacy-policy.scss";

const ComplaintsProcedure = () => {

    return (

        <Layout SEOTitle={"Complaints procedure | Free Motor Legal"} SEODescription={""} footerDisclaimer={""}>
            <Banner props={header}></Banner>
            <div className="privacy-policy-container">

                {
                    complaints.length ? complaints.map((item, index) => {

                        return (
                            <div key={index}>
                                {
                                    item.title ? <span className="bold">{item.title}</span> : ""

                                }
                                {
                                    item.description ? Array.isArray(item.description) ? item.description.map((itemText, index) => {

                                        return (
                                            <p style={{ margin: "5px" }} key={index}>{itemText.text}</p>
                                        )
                                    }) : <p>{item.description}</p> : ""
                                }
                                {
                                    item.itemList ?
                                        <div style={{ paddingBottom: "20px" }}>
                                            {
                                                item.itemList?.length ? item.itemList.map((listItem, index) => {

                                                    return (
                                                        <>
                                                            <ListItem text={listItem.title} key={index} />
                                                            <span>{listItem.description}</span>
                                                        </>
                                                    )
                                                }) : ""
                                            }

                                        </div>
                                        : ""
                                }
                            </div>
                        )
                    }) : ""
                }
            </div>
        </Layout>
    )
}

export default ComplaintsProcedure;